import React from 'react';
import { Grid, Stack, Typography, TypographyProps } from '@mui/material';

import { LocalizationContext } from '@cvt/contexts';

type Props = {
  imageSrc?: string;
  message?: string;
  height?: string | number;
  slotProps?: {
    typography: TypographyProps;
  }
}
export const MessageFeedbackView: React.FC<Props> = ({ message, imageSrc, height = '100%', slotProps }) => {

  const { dictionary } = React.useContext(LocalizationContext);

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ height, my: 'auto', minHeight: 200 }}>
      <Grid item>
        <Stack direction="column" spacing={2}>
          {imageSrc && <img src={imageSrc} alt={message} />}
          <Typography component="span" variant="h4" color="textSecondary" textAlign="center" {...slotProps?.typography}>
            {message || dictionary.errors.somethingWentWrong}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};
