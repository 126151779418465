import * as React from 'react';
import {
  Explore,
  Groups,
  IntegrationInstructions,
  LockOpen,
  Map,
  Notifications,
  Person,
  PrivacyTip,
  Search,
  Telegram,
} from '@mui/icons-material';
import { Badge } from '@mui/material';
import { UnreadConversation } from 'talkjs/all';

import { Dictionary } from '@shared/dictionary';

import { CommunityUnreadConversation } from '@modules/Users/contexts/UserContext/types';

export const routes = {
  home: '/',
  onboarding: {
    path: '/onboarding',
    routes: {
      subscriber: {
        path: '/subscriber',
        routes: {
          step: (stepNumber: string = ':stepNumber') => `/${stepNumber}`,
        },
      },
      step: (stepNumber: string = ':stepNumber') => `/${stepNumber}`,
    },
  },
  phoneVerification: '/phone-verification',
  profile: {
    path: '/my-account',
    routes: {
      tab: (tab: string = ':tab') => `/${tab}`,
    },
  },
  invitation: {
    path: '/invitation',
    routes: {
      invite: (token: string = ':token') => `/${token}`,
      community: (token: string = ':token') => `/community/${token}`,
    },
  },
  auth: {
    path: '/auth',
    routes: {
      login: '/login',
      ericContactsLogin: '/login-ec',
      signup: '/signup',
      impersonate: '/impersonate',
      resetPassword: '/reset-password',
      resetPasswordConfirm: '/reset-password-confirm',
      subscriberSignup: '/subscriber-signup',
      experience: {
        path: '/experience',
        routes: {
          network: '/network',
          communities: '/communities',
          profile: '/profile',
        },
      },
    },
  },
  users: {
    path: '/users',
    routes: {
      view: (userId: string | number = ':userId') => `/${userId}`,
      edit: (userId: string | number = ':userId') => `/edit/${userId}`,
      create: '/create',
      map: '/map',
    },
  },
  trips: {
    path: '/trips',
    routes: {
      view: (tripId: string | number = ':tripId') => `/${tripId}`,
      edit: (tripId: string | number = ':tripId') => ({
        path: `/edit/${tripId}`,
        routes: {
          notifications: '/notifications',
        },
      }),
    },
  },
  communities: {
    path: '/communities',
    routes: {
      view: (communityId: string | number = ':communityId') => ({
        path: `/${communityId}`,
        routes: {
          details: '/details',
          general: '/general',
          channel: (channelId: string | number = ':channelId') => ({
            path: `/${channelId}`,
            routes: {
              details: '/details',
              general: '/general',
              auth: '/auth',
            },
          }),
          editChannel: (channelId: string | number = ':channelId') => `/edit/${channelId}`,
        },
      }),
      edit: (communityId: string | number = ':communityId') => `/edit/${communityId}`,
    },
  },

  around: '/around',

  events: '/events',

  inbox: {
    path: '/inbox',
    routes: {
      tab: (tab: string = ':tab') => `/${tab}`,
      view: (chatId: string | number = ':chatId') => `/view/${chatId}`,
      edit: (chatId: string | number = ':chatId') => `/edit/${chatId}`,
    },
  },

  network: '/network',
  networks: '/networks',

  spheres: {
    path: '/spheres',
    routes: {
      tab: (tab: string = ':tab') => `/${tab}`,
    },
  },
  contacts: '/contacts',
  notifications: '/notifications',
  integrations: {
    path: '/integrations',
    routes: {
      success: (connection: string = ':connection') => `/${connection}/success`,
    },
  },
  search: {
    path: '/search',
    routes: {
      tab: (tab: string = ':tab') => `/${tab}`,
    },
  },

  termsAndConditions: '/terms-and-conditions',
  privacyPolicy: '/privacy-policy',
  feedback: '/feedback',


  dashboard: {
    path: '/dashboard',
    routes: {
      trips: {
        path: '/trips',
        routes: {
          swipe: '/swipe',
        },
      },
      events: {
        path: '/events',
        routes: {
          swipe: '/swipe',
        },
      },
      users: {
        path: '/users',
        routes: {
          swipe: '/swipe',
          recent: '/recent',
        },
      },
      alerts: {
        path: '/alerts',
        routes: {
          swipe: '/swipe',
        },
      },
      contacts: {
        path: '/contacts',
        routes: {
          swipe: '/swipe',
        },
      },
    },
  },
};

export const navigation = (
  router: CVT.Routing.RecursiveRoutes<typeof routes>,
  dictionary: Dictionary,
  config: CVT.Config,
  unreadMessages: {
    direct: UnreadConversation[];
    communities: CommunityUnreadConversation[];
  },
  notificationsCounter?: number,
  userId?: number,
): CVT.Navigation.Config => {
  const navigation = {
    userMenu: [{
      icon: <Person fontSize="small"/>,
      text: dictionary.menu.users.accountSettings,
      route: router.profile.tab('profile').path,
      requiresAuth: true,
    }, {
      icon: 
        <Badge badgeContent={unreadMessages.direct.length} color="error">
          <Telegram fontSize="small"/>
        </Badge>,
      text: dictionary.menu.users.messages,
      route: router.inbox.tab('active').path,
      requiresAuth: true,
    }, {
      icon:
        <Badge badgeContent={notificationsCounter} color="error">
          <Notifications fontSize="small"/>
        </Badge>,
      text: dictionary.menu.users.notifications,
      route: router.notifications.path,
      requiresAuth: true,
    }, {
      icon: <Explore fontSize="small"/>,
      text: dictionary.menu.trips.list,
      route: router.trips.path,
      requiresAuth: true,
    }, {
      icon: <Search fontSize="small"/>,
      text: dictionary.menu.search,
      route: router.search.tab('top').path,
      requiresAuth: true,
    }, {
      icon: <PrivacyTip fontSize="small"/>,
      text: dictionary.menu.users.privacy,
      route: router.profile.tab('privacy').path,
      requiresAuth: true,
    }, {
      icon: <IntegrationInstructions fontSize="small"/>,
      text: dictionary.menu.integrations,
      route: router.integrations.path,
      requiresAuth: true,
    }, {
      icon: <LockOpen fontSize="small"/>,
      text: dictionary.menu.auth.login,
      route: router.auth.login.path,
      requiresAuth: false,
    }, {
      icon: <LockOpen fontSize="small"/>,
      text: dictionary.menu.auth.signUp,
      route: router.auth.signup.path,
      requiresAuth: false,
    }, {
      text: dictionary.menu.term,
      route: router.termsAndConditions.path,
    }, {
      text: dictionary.menu.privacy,
      route: router.privacyPolicy.path,
    }, {
      text: dictionary.menu.feedback,
      route: router.feedback.path,
    }],
    sidebar: [{
      icon: <Map color="inherit"/>,
      text: dictionary.menu.dashboard,
      route: router.home.path,
      requiresAuth: true,
    }, {
      divider: true,
    }, {
      icon: <Search color="inherit"/>,
      text: dictionary.menu.search,
      route: router.search.tab('top').path,
      requiresAuth: true,
    }, {
      divider: true,
    }, {
      icon: <Groups color="inherit"/>,
      text: dictionary.menu.communities.list,
      route: router.network.path,
      requiresAuth: true,
    }],
  };

  return navigation;
};
