import React from 'react';
import { Box, BoxProps, Typography } from '@mui/material';

import { DialogContext } from '@cvt/contexts';

import { StackedCards } from '@shared/components/StackedCards';

import { useTrips } from '../hooks/useTrips';
import backgroundLight from '../assets/tripLightAnimatedBackground.svg';
import backgroundDark from '../assets/tripDarkAnimatedBackground.svg';
import { TripCard, TripCardSkeleton } from './TripCard';

interface Props extends BoxProps {
  showSubtitle?: boolean;
  params?: Partial<Trips.ListParams>;
  onClick?: (params: any) => void;
}

export const StackedTrips: React.FC<Props> = ({ title, showSubtitle = true, onClick, params, ...props }) => {
  const { openDialog } = React.useContext(DialogContext);

  const { trips, status } = useTrips({
    limit: 500,
    ...params,
  });

  if (status === 'loading') {
    return (
      <StackedCards>
        <TripCardSkeleton/>
        <TripCardSkeleton/>
        <TripCardSkeleton/>
      </StackedCards>
    );
  }

  if (status === 'success' && !trips.length) {
    return (
      <Box
        width="100%"
        border={1}
        bgcolor="secondary.main"
        borderColor="secondary.dark"
        sx={theme => ({
          zIndex: trips.length + 1,
          p: 2,
          borderRadius: 3,
          boxShadow: theme.shadows[6],
          aspectRatio: '4 / 2',
          backgroundImage: `url(${theme.palette.mode === 'light' ? backgroundDark : backgroundLight})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        })}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          openDialog('createTrip');
          return false;
        }}
      >
        <Typography variant="h1" fontWeight={500} color="primary.main" sx={theme => ({ textShadow: theme.palette.mode === 'light' ? 'none' : '0px 0px 3px rgba(25, 25, 25, 0.6)' })}>No Trips</Typography>
        {showSubtitle && (
          <Typography variant="body2" color="primary.main" sx={theme => ({ textShadow: theme.palette.mode === 'light' ? 'none' : '0px 0px 3px rgba(25, 25, 25, 0.6)' })}>Create your next trip</Typography>
        )}
      </Box>
    );
  }

  return (
    <Box position="relative" onClick={() => onClick && onClick({})} {...props}>
      <StackedCards>
        <Box
          position="absolute"
          top={0}
          width="100%"
          border={1}
          bgcolor="secondary.main"
          borderColor="secondary.dark"
          sx={theme => ({
            zIndex: trips.length + 1,
            p: 2,
            borderRadius: 3,
            boxShadow: theme.shadows[6],
            aspectRatio: '4 / 2',
            backgroundImage: `url(${theme.palette.mode === 'light' ? backgroundDark : backgroundLight})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          })}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h1" fontWeight={500} color="primary.main" sx={theme => ({ textShadow: theme.palette.mode === 'light' ? 'none' : '0px 0px 3px rgba(25, 25, 25, 0.6)' })}>{trips.length} Upcoming Trips</Typography>
        </Box>
        {trips.map((trip) => (
          <TripCard
            key={trip.id}
            trip={trip}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              onClick && onClick({ tripId: trip.id });
              return null;
            }}
          />
        ))}
      </StackedCards>
    </Box>
  );
};
