import React from 'react';
import {
  Typography,
  Stack,
  Box,
  BoxProps,
  Avatar,
} from '@mui/material';
import { Circle } from '@mui/icons-material';

import { darkBlue } from '@shared/theme/palette';
import { getDateDotColor } from '@shared/helpers/date';

import { formatTripDates } from '@modules/Trips/helpers/date';


interface Props extends BoxProps {
  trip: Trips.Trip;
  hideShadow?: boolean;
  isFront?: boolean;
}

export const TripCard: React.FC<Props> = ({ trip, hideShadow = false, isFront = false, ...props }) => {
  const circleColor = React.useMemo(() => {
    return trip ? getDateDotColor(trip.arrivalDatetime, trip.departureDatetime) : undefined;
  }, [trip]);

  const [photoUrl, setPhotoUrl] = React.useState('');

  React.useEffect(() => {
    const fetchPlacePhoto = () => {
      try {
        const mapElement = document.createElement('div');
        // @ts-ignore
        const service = new google.maps.places.PlacesService(mapElement);

        const request = {
          query: trip.location.title,
          fields: ['photos'],
          locationBias: { lat: trip.location.latitude, lng: trip.location.longitude },
        };

        service.findPlaceFromQuery(request, (results: any, status: any) => {
          // @ts-ignore
          if (status === google.maps.places.PlacesServiceStatus.OK && results[0]?.photos) {
            const photo = results[0].photos[0];
            const photoUrl = photo.getUrl({ maxWidth: 1600, maxHeight: 900 });
            setPhotoUrl(photoUrl);
          }
        });
      } catch(err) {
        console.log(err);
      }
    };

    // @ts-ignore
    if (window.google && window.google.maps) {
      fetchPlacePhoto();
    } else {
      const script = document.createElement('script');
      // Need to add visualization library because this script may be used to load Heatmap - https://github.com/google-map-react/google-map-react/blob/master/API.md#important-note
      script.src = `https://maps.googleapis.com/maps/api/js?key=${import.meta.env.VITE__CVT_GOOGLE_MAPS_KEY}&libraries=places,visualization`;
      script.async = true;
      script.onload = fetchPlacePhoto;
      document.head.appendChild(script);
    }
  }, [trip.location]);

  return (
    <Box
      {...props}
      width="100%"
      border={1}
      bgcolor="secondary.main"
      borderColor="secondary.dark"
      sx={theme => ({
        borderRadius: 3,
        boxShadow: hideShadow ? undefined : theme.shadows[6],
        color: theme.palette.mode === 'light' ? darkBlue : theme.palette.text.secondary,
        aspectRatio: '4 / 2',
        display: 'flex',
        overflow: 'hidden',
      })}
    >
      {isFront ? (
        <Stack width="100%" p={1.5} direction="column">
          <Stack direction="row" alignItems="center" spacing={1} height="100%" pt={1.5}>
            <Avatar src={photoUrl} sx={{ width: 80, height: 80 }} />
            <Stack overflow="hidden">
              <Typography variant="h1" fontWeight={500} fontFamily="Roboto" letterSpacing={1} whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
                {trip?.location.title}
              </Typography>
            </Stack>
          </Stack>
          <Stack width="100%" direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
            <Typography variant="body3" fontWeight={500} color="primary.light">
              {trip?.departureDatetime && trip?.arrivalDatetime && `${formatTripDates(trip?.arrivalDatetime, trip?.departureDatetime)}`}
            </Typography>
            {/*{circleColor && (*/}
            {/*  <Circle fontSize="inherit" color={circleColor}/>*/}
            {/*)}*/}
          </Stack>
        </Stack>
      ) : (
        <Stack width="100%" mt="auto" p={1.5} pt={6} direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Avatar src={photoUrl} sx={{ width: 28, height: 28 }} />
            <Stack overflow="hidden">
              <Typography variant="body1" fontWeight={500} noWrap textOverflow="ellipsis" overflow="hidden">
                {trip?.location.title}
              </Typography>
            </Stack>
          </Stack>
          {trip?.departureDatetime && trip?.arrivalDatetime && (
            <Stack direction="row" alignItems="center" spacing={1} overflow="hidden">
              <Typography variant="body3" fontWeight={500} color="primary.light" noWrap textOverflow="ellipsis">
                {formatTripDates(trip?.arrivalDatetime, trip?.departureDatetime)}
              </Typography>
              {/*{circleColor && (*/}
              {/*  <Circle fontSize="inherit" color={circleColor}/>*/}
              {/*)}*/}
            </Stack>
          )}
        </Stack>
      )}
    </Box>
  );
};

export const TripCardSkeleton: React.FC = () => {
  return (
    <Box
      width="100%"
      border={1}
      bgcolor="secondary.main"
      borderColor="secondary.dark"
      sx={theme => ({
        p: 1.5,
        borderRadius: 3,
        color: theme.palette.mode === 'light' ? darkBlue : theme.palette.text.secondary,
        boxShadow: theme.shadows[6],
        aspectRatio: '4 / 2',
        display: 'flex',
      })}
    />
  );
};

