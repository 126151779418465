import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography, Stack, Button, IconButton, Divider } from '@mui/material';
import { Block, HighlightOff, Public, InsertInvitationTwoTone, EmailTwoTone, Textsms, Telegram } from '@mui/icons-material';

import { DialogContext, FeedbackContext } from '@cvt/contexts';
import { useDictionary } from '@cvt/hooks/useDictionary';
import { useRouter } from '@cvt/hooks/useRouter';
import { MessageFeedbackView } from '@cvt/components/MessageFeedbackView';
import { BodyLoading } from '@cvt/components/layout/BodyLoading';

import { AuthContext } from '@modules/Auth/contexts';
import { Authenticated, NotAuthenticated } from '@modules/Auth/components';
import { formatTripDates } from '@modules/Trips/helpers/date';
import { useConnectionChange } from '@modules/Connections/hooks/useConnectionChange';
import { useChatCrud } from '@modules/Messaging/hooks/useChatCrud';
import { sendSms } from '@modules/Contacts/helpers/mobile';
import { ConnectionButtonSelect } from '@modules/Connections/components/ConnectionButtonSelect';
import { StackedTrips } from '@modules/Trips/components/StackedTrips';

import { SOCIAL_ICONS, SOCIAL_PROFILES, getHandle, getLink } from '@shared/helpers/social';
import { copyToClipboard } from '@shared/helpers/navigator';
import { isNative } from '@shared/helpers/environment';
import { View404 } from '@shared/components/404';
import { blue, darkBlue } from '@shared/theme/palette';

import { useUser } from '../hooks/useUser';

const ViewUserView: React.FC<Users.User> = user => {
  const { isLoggedIn, user: me } = React.useContext(AuthContext);
  const { asyncConfirmation, openDialog } = React.useContext(DialogContext);
  const { triggerFeedback } = React.useContext(FeedbackContext);
  const dictionary = useDictionary();
  const router = useRouter();

  const { createChat } = useChatCrud();

  const { onConnectionTypeChange } = useConnectionChange({
    user,
  });
  
  const onChatRequest = React.useCallback(async () => {
    const response = await createChat({
      users: [user.id],
    });
    router.inbox.view(response.data.id).go();
  }, [createChat, user, router]);

  const onBlockRequest = React.useCallback(async () => {
    const userConfirmed = await asyncConfirmation({ title: dictionary.users.connection.deleteConfirmation, content: ' ', confirmLabel: dictionary.users.connection.buttonBlock });
    if (!userConfirmed) {
      return false;
    }
    return onConnectionTypeChange('block');
  }, [dictionary, asyncConfirmation, onConnectionTypeChange]);

  const handleCopyToClipboard = async (text: string, feedbackText: string) => {
    await copyToClipboard(text);

    triggerFeedback({
      severity: 'success',
      message: feedbackText,
    });
  };

  const onSendMessage = React.useCallback(async () => {
    sendSms(`${user?.phone}`);
  }, [user?.phone]);

  const isCurrentUserView = React.useMemo(() => user.id === me?.id, [user, me]);
  const isUserConnected = React.useMemo(() => (user.connection?.type === 'general' || user.connection?.type === 'close') && user.connection?.status === 'active', [user]);
  const isUserSetMeetLink = React.useMemo(() => isUserConnected && !!user.bookMeetingLink, [isUserConnected, user.bookMeetingLink]);

  const SocialUserButtons = () => {
    if (!SOCIAL_PROFILES.some(social => user[`${social}Url`])) {
      return null;
    }

    return (
      <Box px={2}>
        {isLoggedIn && <Typography variant="h5" color="text.secondary">Socials</Typography>}
        <Stack direction="row" spacing={2} justifyContent={isLoggedIn ? 'initial' : 'center'}>
          {SOCIAL_PROFILES.map(social => {
            const input = user[`${social}Url`];
            if (!input) {
              return null;
            }
            return (
              <IconButton
                component={Link}
                to={getLink(getHandle(input), social)}
                color="secondary"
                size="small"
                key={social}
              >
                {SOCIAL_ICONS[social]}
              </IconButton>
            );
          })}
        </Stack>
      </Box>
    );
  };

  return (
    <Stack direction="column">
      {isCurrentUserView && (
        <Box p={2}>
          <Typography variant="body2">You're viewing your own profile</Typography>
        </Box>
      )}
      <Box
        display="flex"
        flexDirection="column"
        height="60vh"
        sx={theme => ({
          backgroundColor: blue,
          color: 'common.white',
          backgroundImage: `url(${user.profilePictureSizes?.[500]})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          overflow: 'hidden',
        })}
      >
        <Stack px={3} p={2} direction="row" justifyContent="space-between" alignItems="center">
          <Stack spacing={1} direction="row">
            {user.thrUsername && (
              <Button color="primary" variant="contained" size="small" onClick={() => openDialog('userQrCode', { userId: user?.id || '' })}>
                @{user.thrUsername}
              </Button>
            )}
            {isCurrentUserView && (
              <Button color="primary" variant="contained" size="small" onClick={() => router.profile.tab('profile').go()}>
                Settings
              </Button>
            )}
          </Stack>
          {isLoggedIn && !isCurrentUserView && (
            <ConnectionButtonSelect user={user} canToggle={isUserConnected}/>
          )}
        </Stack>
        <Box mt="auto" width="100%" p={3} pt={6} sx={theme => ({ background: `linear-gradient(to bottom, transparent, ${darkBlue})` })}>
          <Stack width="100%" direction="row" justifyContent="space-between">
            <Stack mt="auto" sx={{ textShadow: '0 0 4px rgba(0, 0, 0, 0.8)' }}>
              <Typography variant="h1" fontSize={32} fontWeight={600}>
                {user.displayName}
              </Typography>
              <Stack spacing={1} direction="column">
                Currently in {user.trip ? user.trip.location.title : user.baseLocation?.title || 'Unknown'}
                {user.trip ? ` - ${formatTripDates(user.trip.arrivalDatetime, user.trip.departureDatetime)}` : null}
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Box>
      <Box pt={3} pb={2}>
        <NotAuthenticated>
          <Stack alignItems="center" spacing={2} justifyContent="center" sx={theme => ({
            mx: 2,
            mb: 2,
          })}>
            <Typography variant="h2" align="center" fontWeight={500}>HERE'S WHERE I'LL BE NEXT</Typography>
            <Typography variant="h3" align="center">Want to meet up? Hit subscribe and get a heads-up when I'm in town. Let's make it happen!</Typography>
            <Button color="primary" variant="contained" size="medium" onClick={() => router.auth.subscriberSignup.go( { userId: user.id })}>
              Subscribe
            </Button>
            <Divider sx={{ width: '100%' }}/>
          </Stack>
        </NotAuthenticated>
        {user.bio && isLoggedIn && (
          <Box
            px={2}
            pb={3}
          >
            <Typography variant="h5" color="text.secondary">About</Typography>
            <Typography variant="body1" fontSize={20}>{user.bio}</Typography>
          </Box>
        )}
        <Authenticated>
          <Box
            px={2}
            pb={3}
          >
            <Box display="flex" gap={1} flexDirection="row" flexWrap="wrap">
              {isLoggedIn && !isCurrentUserView && isUserConnected && (
                <Button variant="contained" color="secondary" onClick={onChatRequest} startIcon={<Telegram/>}>
                Message
                </Button>
              )}
              {isUserSetMeetLink && <Button href={user.bookMeetingLink || ''} target="_blank" rel="noreferrer noopener" variant="contained" color="secondary" startIcon={<InsertInvitationTwoTone/>}>Book a meeting</Button>}
              {user.email && (
                <Button
                  href={`mailto:${user.email}`}
                  variant="contained"
                  color="secondary"
                  startIcon={<EmailTwoTone/>}
                >
                Email
                </Button>
              )}
              {user.phone && (
                <Button onClick={() => isNative() ? onSendMessage() : handleCopyToClipboard(user?.phone || '', dictionary.users.profile.clipboardSaved('Phone number'))} variant="contained" color="secondary" startIcon={<Textsms/>}>{isNative() ? 'SMS' : 'Phone'}</Button>
              )}
            </Box>
          </Box>
        </Authenticated>
        <Box
          px={2}
          pb={1}
        >
          <StackedTrips
            showSubtitle={false}
            params={{
              user: user.id,
            }}
          />
        </Box>
        <SocialUserButtons />
        <Authenticated>
          {!isCurrentUserView && (
            <Stack px={2} mt={3} spacing={2}>
              <Divider/>
              <Stack direction="row" justifyContent="space-around">
                {user.connection?.type !== 'block' && (
                  <Button startIcon={<Block/>} variant="text" color="inherit" onClick={onBlockRequest}>
                    Block user
                  </Button>
                )}
                {user.connection?.type === 'block' && (
                  <Button startIcon={<Public/>} variant="text" color="inherit" onClick={() => onConnectionTypeChange('general')}>
                    Unblock user
                  </Button>
                )}
                {['general', 'close'].includes(user.connection?.type || 'public') && (
                  <Button startIcon={<HighlightOff/>} variant="text" color="inherit" onClick={() => onConnectionTypeChange('public')}>
                    Remove from Network
                  </Button>
                )}
              </Stack>
            </Stack>
          )}
        </Authenticated>
      </Box>
    </Stack>
  );
};

export const ViewUser: React.FC<{ id: string }> = ({ id }) => {

  const { isLoggedIn } = React.useContext(AuthContext);

  const { user, status, error } = useUser({
    id,
    authenticate: isLoggedIn,
  });
  
  if (status === 'loading') {
    return <BodyLoading height="100%" />;
  }
  
  if (status === 'error' && error?.request?.status === 404) {
    return (
      <View404 message="User not found" />
    );
  }
  
  if (status === 'error' || !user) {
    return <MessageFeedbackView height="100%" />;
  }

  return <ViewUserView {...user} />;
};
