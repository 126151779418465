import { Dictionary } from '@shared/dictionary';

export const getConnectionLabel = (dictionary: Dictionary, value: string) => {
  const MAP = {
    'public': 'Public',
    'general-network': 'General',
    'general': 'General',
    'close-network': 'Close',
    'close': 'Close',
    'hidden': 'Hidden',
  };

  // @ts-ignore
  return MAP[value];
};
