import React from 'react';
import { useNavigate } from 'react-router';
import TextLoop from 'react-text-loop';
import { Box, Paper, Typography, Stack, IconButton, Button, Avatar, useTheme } from '@mui/material';
import { ChevronLeft, LockSharp, NumbersSharp, NotificationsOffOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Chatbox } from '@talkjs/react';

import { useRouter } from '@cvt/hooks/useRouter';
import { MessageFeedbackView } from '@cvt/components/MessageFeedbackView';
import { BodyLoading } from '@cvt/components/layout/BodyLoading';

import { UserContext } from '@modules/Users/contexts';
import { formatParticipantDisplayNames } from '@modules/Messaging/helpers/participants';

import { useCommunityChannel } from '../hooks/useCommunityChannel';
import { useCommunityCrud } from '../hooks/useCommunityCrud';
import { CommunityChannelActions } from '../components/CommunityChannelActions';
import { useMembers } from '../hooks/useMembers';
import { useCommunity } from '../hooks/useCommunity';
import { CommunityContext, CommunityProvider } from '../contexts/CommunityContext';

interface Props extends Communities.Channels.Channel {
  hideNavigationBack: boolean;
}

export const ViewCommunityChannelView: React.FC<Props> = ({ hideNavigationBack, ...channel }) => {
  const theme = useTheme();

  const { user: me } = React.useContext(UserContext);
  const { community } = React.useContext(CommunityContext);
  
  const { joinChannel, joinChannelLoading, joinCommunity, joinCommunityLoading } = useCommunityCrud();
  
  const navigate = useNavigate();
  const router = useRouter();

  const amIChannelMember = React.useMemo(() => {
    const user = channel?.users.find(user => user.id === me?.id);

    if (user) {
      return true;
    }

    return false;
  }, [me, channel]);

  const amIApprovedChannelMember = React.useMemo(() => {
    const user = channel?.users.find(user => user.id === me?.id);
    if (user) {
      return user.status === 'active';
    }

    return false;
  }, [me, channel]);

  const isUserPartOfChannel = React.useMemo(() => {
    return community.users.some(member => member.id === me?.id);
  }, [community, me]);

  const onJoinChannel = React.useCallback(async () => {
    if (!isUserPartOfChannel) {
      await joinCommunity({ id: community.id, handle: community.handle });
    }

    await joinChannel({ communityId: community.id.toString(), channelId: channel.id, handle: community.handle });
  }, [isUserPartOfChannel, joinChannel, community, channel, joinCommunity]);

  return (
    <Box display="flex" flexDirection="column" width="100%" height="100%">
      <Stack width="100%" direction="row" justifyContent="space-between" alignItems="center" spacing={0.5} py={1} px={1} flex="0 1 auto">
        <Box flex={1}>
          {!hideNavigationBack && (
            <IconButton size="small" onClick={() => navigate(-1)}>
              <ChevronLeft/>
            </IconButton>
          )}
        </Box>
        <Stack
          width="100%"
          direction="row"
          alignItems="center"
          spacing={0}
          overflow="hidden"
          onClick={() => amIApprovedChannelMember && router.communities.view(community.handle || community.id).channel(channel.id).details.go()}
        >
          <Avatar sx={{ width: 30, height: 30, bgcolor: 'transparent' }}>
            {channel.private ? <LockSharp fontSize="inherit"/> : <NumbersSharp fontSize="inherit" />}
          </Avatar>
          <Stack>
            <Stack direction="row" alignItems="flex-start" spacing={0} overflow="hidden" height={20}>
              <Typography variant="h4" component="h1" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                {channel.name}
              </Typography>
              {channel.muted && (<NotificationsOffOutlined fontSize="small" sx={{ mx: 1 }}/>)}
            </Stack>
            <Typography variant="body2" component="span" color="secondary.light" lineHeight={1}>
              <TextLoop interval={6000}>
                <span>{channel.users.length} members</span>
                <span>{formatParticipantDisplayNames(channel.users, 'firstName')}</span>
              </TextLoop>  
            </Typography>
          </Stack>
        </Stack>
        {amIApprovedChannelMember && (
          <Box flex={1} textAlign="right">
            <Stack direction="row" spacing={1} alignItems="center">
              <CommunityChannelActions
                {...channel}
                amIApprovedMember={amIApprovedChannelMember}
              />
            </Stack>
          </Box>
        )}
      </Stack>
      <Paper component={Box} flex="1 1 auto" variant="transparent">
        {channel.private && !amIApprovedChannelMember ? (
          <React.Fragment>
            <MessageFeedbackView message="This Channel is private." />
          </React.Fragment>
        ) : (
          <Chatbox
            theme={theme.palette.mode === 'light' ? 'default' : 'default_dark'}
            conversationId={channel.id}
            showChatHeader={false}
            loadingComponent={<BodyLoading height="100%" />}
            style={{ width: '100%', height: '100%' }}
            onCustomMessageAction={event => {
              if (event.action === 'goToProfile') {
                router.users.view(event.params.userId).go();
              }
            }}
            messageField={{ visible: amIChannelMember }}
          />
        )}
      </Paper>
      {!amIChannelMember && (
        <Box p={2}>
          <LoadingButton
            fullWidth
            variant="contained"
            size="large"
            loading={joinChannelLoading || joinCommunityLoading}
            onClick={() => onJoinChannel()}
          >
            {channel.private ? 'Request to join channel' : 'Join channel'}
          </LoadingButton>
        </Box>
      )}
      {amIChannelMember && !amIApprovedChannelMember && channel.private && (
        <Box p={2}>
          <Button size="large" fullWidth disabled>
            Request to join channel pending Community Admin approval
          </Button>
        </Box>
      )}
    </Box>
  );
};

export const ViewCommunityChannel: React.FC<{ communityId: string, id: string, hideNavigationBack?: boolean }> = ({ communityId, id, hideNavigationBack = false }) => {

  const { community, status: communityStatus } = useCommunity({
    id: communityId,
  });
  const { members, status: membersStatus } = useMembers({
    communityId,
  });
  const { channel, status: channelStatus } = useCommunityChannel({
    communityId: communityId,
    id: id.toString(),
  });

  if ([communityStatus, membersStatus, channelStatus].includes('loading')) {
    return <BodyLoading height="100%" />;
  }
  
  if ([communityStatus, membersStatus, channelStatus].includes('error') || !community || !members || !channel) {
    return <MessageFeedbackView height="100%" />;
  }

  return (
    <CommunityProvider {...community} users={members}>
      <ViewCommunityChannelView hideNavigationBack={hideNavigationBack} {...channel} />
    </CommunityProvider>
  );
};
