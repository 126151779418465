import React from 'react';
import { Stack, Box, Typography, Container, Button } from '@mui/material';

import { UserContext } from '@modules/Users/contexts';
import { useUserCrud } from '@modules/Users/hooks/useUserCrud';


export const PayWall: React.FC = () => {
  const { user } = React.useContext(UserContext);
  const { editUser } = useUserCrud();

  const onBecomePro = React.useCallback(() => {
    if (!user) {
      return false;
    }
    return editUser({
      ...user,
      userType: 'pro',
      onboarded: false,
    });
  }, [user, editUser]);

  return (
    <Container sx={{ height: '100%' }}>
      <Box position="relative" height="100%">
        <Stack
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          height="100%"
          direction="column"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          spacing={5}
        >
          <Stack
            direction="column"
            alignItems="center"
            spacing={2}
            sx={(theme) => ({
              position: 'relative',
              paddingX: 3,
              paddingY: 5,
            })}
          >
            <Box>
              <Typography variant="h1" fontSize={30} mb={4} fontWeight={600} lineHeight={1}>HERE'S THE DEAL</Typography>
              <Typography variant="body1" mb={1} fontWeight={500}>
                Thr is an invite-only, membership-based platform that lets you sort out your network like a pro and make sure
                you never miss a chance to meet up when you and your people are in the same city.
              </Typography>
              <Typography variant="body1" mb={1} fontWeight={500}>
                You call the shots - only the folks you choose will get the heads-up.
              </Typography>
              <Typography variant="body1" mb={1} fontWeight={500}>
                No awkward surprises, no random alerts. It's all secure, and you're in control.
              </Typography>
              <Typography variant="body1" mb={5} fontWeight={500}>
                So go ahead, download Thr, join the club, and start making those epic meetups happen."
              </Typography>
            </Box>

            <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
              <Button fullWidth size="large" onClick={onBecomePro}>
                Become Pro
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Container>
  );
};

export default PayWall;