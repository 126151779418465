import React from 'react';
import QRCode from 'react-qr-code';
import noop from 'lodash/noop';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  SwipeableDrawer,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { Share } from '@capacitor/share';

import { useRouter } from '@cvt/hooks/useRouter';
import { BodyLoading } from '@cvt/components/layout/BodyLoading';

import { isNative } from '@shared/helpers/environment';

import { useUser } from '@modules/Users/hooks/useUser';


type Props = {
  open: boolean;
  onClose: () => void;
  data?: {
    userId: string;
    onSuccess: () => void;
  }
}

export const UserQrCodeDialog: React.FC<Props> = ({ open, onClose, data }) => {
  const router = useRouter();

  const { status, user } = useUser({
    id: data?.userId || '',
  }, {
    enabled: !!data?.userId,
  });

  const onShare = React.useCallback(() => {
    try {
      Share.share({
        title: user?.displayName,
        url: import.meta.env.VITE__CVT_REACT_APP_BASE_URL + router.users.view(user?.thrUsername || user?.id || '').path,
        dialogTitle: 'Share this user',
      });
    } catch(err) {
      console.error(err);
    }
  }, [router, user]);

  return (
    <SwipeableDrawer
      disableSwipeToOpen
      keepMounted={false}
      anchor="bottom"
      open={open}
      onOpen={noop}
      onClose={onClose}
      sx={theme => ({
        zIndex: theme.zIndex.modal + 1,
      })}
      PaperProps={{
        sx: theme => ({
          left: theme.spacing(1.5),
          right: theme.spacing(1.5),
          bottom: isNative() ? 'env(safe-area-inset-bottom)' : theme.spacing(1.5),
          borderRadius: theme.shape.borderRadius,
          maxWidth: theme.breakpoints.values.sm,
          margin: 'auto',
          backgroundImage: 'none',
        }),
      }}
    >
      <DialogTitle component={Stack} direction="row" alignItems="center" justifyContent="space-between">
        <Box width={40}/>
        <Typography variant="h1">Scan QR Code</Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          color="secondary"
        >
          <Close/>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {status === 'loading' || !user ? (
          <BodyLoading height="35vh"/>
        ) : (
          <Stack mb={2} spacing={3} direction="column" justifyContent="center" alignItems="center">
            <QRCode
              value={import.meta.env.VITE__CVT_REACT_APP_BASE_URL + router.users.view(user?.thrUsername || user?.id || '').path}
            />
            <Button
              color="secondary"
              size="small"
              onClick={onShare}
            >
              Share
            </Button>
          </Stack>
        )}
      </DialogContent>
    </SwipeableDrawer>
  );
};
