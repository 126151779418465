import React from 'react';
import { motion, useAnimation, useMotionValue, useTransform } from 'framer-motion';
import { Typography, Box, useTheme } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SkipNextIcon from '@mui/icons-material/SkipNext';

interface Props {
  onSwiped?: () => void,
  onSwipeLeft?: () => void,
  onSwipeRight?: () => void,
  onClick?: () => void,
  leftLabel?: React.ReactNode;
  rightLabel?: React.ReactNode;
}

export const SwipableCard: React.FC<React.PropsWithChildren<Props>> = ({ 
  children, 
  onClick, 
  onSwiped, 
  onSwipeLeft, 
  onSwipeRight,
  leftLabel = 'Skip',
  rightLabel = 'Mark as Read',
}) => {
  const theme = useTheme();
  const [swipable, setSwipable] = React.useState(true);
  const motionValue = useMotionValue(0);

  const rotation = useTransform(motionValue, [-300, 0, 300], [20, 0, -20]);

  const leftBottomCornerX = useTransform(motionValue, [-300, 0], [-30, 0]);
  const rightBottomCornerX = useTransform(motionValue, [0, 300], [0, 30]);

  const opacityValue = useTransform(
    motionValue,
    [-300, -250, 0, 250, 300],
    [0, 1, 1, 1, 0],
  );

  const leftOverlayOpacity = useTransform(
    motionValue,
    [-200, -100, 0],
    [0.9, 0.9, 0],
  );
  const rightOverlayOpacity = useTransform(
    motionValue,
    [0, 100, 200],
    [0, 0.9, 0.9],
  );

  const animControls = useAnimation();

  return (
    <motion.div
      drag="x"
      style={{
        width: '100%',
        height: '100%',
        x: motionValue,
        rotate: rotation,
        opacity: opacityValue,
        position: 'relative',
        overflow: 'hidden',
        transformOrigin: 'top center',
        zIndex: 1,
        borderRadius: 16,
      }}
      dragConstraints={{ left: -1000, right: 1000 }}
      animate={animControls}
      onClick={onClick}
      onDragEnd={(event, info) => {
        if (swipable) {
          const threshold = window.innerWidth * 0.4; // 40% of screen width
          if (Math.abs(info.offset.x) <= threshold) {
            animControls.start({ x: 0, rotate: 0 });
          } else {
            setSwipable(false);
            const wasSwipedLeft = info.offset.x < 0;
            const swipeOutDistance = wasSwipedLeft ? -window.innerWidth : window.innerWidth;
            animControls.start({ 
              x: swipeOutDistance, 
              rotate: wasSwipedLeft ? -15 : 15, 
            }).then(async () => {
              if (wasSwipedLeft && onSwipeLeft) {
                await onSwipeLeft();
              }
              if (!wasSwipedLeft && onSwipeRight) {
                await onSwipeRight();
              }
              if (onSwiped) {
                onSwiped();
              }
            });
          }
        }
      }}
    >
      <motion.div
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        {React.cloneElement(children as React.ReactElement<any>, {
          onSwiped,
        })}
      </motion.div>
      <motion.div
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: 100,
          height: 100,
          x: leftBottomCornerX,
        }}
      />
      <motion.div
        style={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          width: 100,
          height: 100,
          x: rightBottomCornerX,
        }}
      />
      <motion.div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          opacity: leftOverlayOpacity,
          background: `linear-gradient(to bottom, ${theme.palette.warning.main}, ${theme.palette.warning.dark}`,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          pointerEvents: 'none',
        }}
      >
        <Box position="absolute" top={0} right={0} sx={{ color: 'white', textAlign: 'center' }}>
          {leftLabel}
        </Box>
      </motion.div>
      <motion.div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          opacity: rightOverlayOpacity,
          background: `linear-gradient(to bottom, ${theme.palette.success.main}, ${theme.palette.success.dark}`,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          pointerEvents: 'none',
        }}
      >
        <Box position="absolute" top={0} left={0} sx={{ color: 'white', textAlign: 'center' }}>
          {rightLabel}
        </Box>
      </motion.div>
    </motion.div>
  );
};