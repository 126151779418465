import React from 'react';
import { Link } from 'react-router-dom';
import { matchPath, useLocation } from 'react-router';
import { AppBar, Avatar, Badge, Box, Divider, Hidden, IconButton, Stack, Toolbar, Typography } from '@mui/material';
import { Menu, Search, Telegram } from '@mui/icons-material';

import { useRouter } from '@cvt/hooks/useRouter';

import { AuthContext } from '@modules/Auth/contexts';
import { Authenticated } from '@modules/Auth/components';
import { UserContext } from '@modules/Users/contexts';

import { Logo } from '@shared/components/Logo';
import useNetworkSearchParams from '@shared/hooks/useNetworkSearchParams';
import { LayoutContext } from '@shared/contexts/LayoutContext/LayoutContext';

import { UserMenu } from './UserMenu';
import { SimpleHeader } from './SimpleHeader';

export const Header: React.FC<React.PropsWithChildren> = ({ children }) => {
  const router = useRouter();
  const location = useLocation();
  const { setNavigationOpen, isMobile } = React.useContext(LayoutContext);
  const { isLoggedIn } = React.useContext(AuthContext);
  const { user, unreadMessages } = React.useContext(UserContext);

  const networkSearchParams = useNetworkSearchParams();

  const [isUserMenuOpen, setIsUserMenuOpen] = React.useState(false);

  const handleOpenUserMenu = () => {
    setIsUserMenuOpen(true);
  };

  const handleCloseUserMenu = () => {
    setIsUserMenuOpen(false);
  };

  const toggleNavigation = () => {
    setNavigationOpen(open => !open);
  };

  // TODO: Delete or uncomment not simple header (if need top user navigation, messages and notifications)
  // const { notifications } = useNotifications({ limit: 20 }, {
  //   enabled: isLoggedIn,
  // });
  //
  const showSimpleHeader = React.useMemo(() => {
    const paths = [
      router.auth.path,
      router.onboarding.path,
      router.onboarding.step().path,
    ];

    return paths.some(path => matchPath(path, location.pathname));
  }, [router, location]);

  const isChatRouteActive = React.useMemo(() => {
    const paths = [
      router.inbox.tab('active').path,
      router.inbox.tab('archived').path,
    ];
    return paths.some(path => matchPath(path, location.pathname));
  }, [router, location.pathname]);


  const hideHeader = React.useMemo(() => {
    const paths = [
      router.search.tab().path,
    ];
    return paths.some(path => matchPath(path, location.pathname));
  }, [router, location.pathname]);

  if (hideHeader) {
    return <></>;
  }

  if (showSimpleHeader) {
    return <SimpleHeader />;
  }

  return (
    <AppBar
      position="fixed"
      elevation={0}
      color="primary"
    >
      <Toolbar
        sx={{
          ...(!isLoggedIn && {
            justifyContent: 'center',
            flexDirection: 'column',
          }),
        }}
      >
        <Hidden xsUp smUp>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={toggleNavigation}
          >
            <Menu />
          </IconButton>
        </Hidden>
        <Stack width="100%" flexGrow={0} direction="row" alignItems="center" spacing={1.5}>
          <Authenticated>
            {/* <IconButton
              size="large"
              color={isChatRouteActive ? 'primary' : 'secondary'}
              component={Link}
              to={router.inbox.tab('active').path}
            >
              <Badge badgeContent={unreadMessages.direct.length} color="error">
                <Telegram/>
              </Badge>
            </IconButton> */}
            <IconButton
              size="large"
              component={Link}
              to={router.search.tab('top').path}
            >
              <Search/>
            </IconButton>
            {/* <IconButton
              size="large"
              color={matchPath(router.notifications.path, location.pathname) ? 'primary' : 'secondary'}
              edge="end"
              component={Link}
              to={router.notifications.path}
            >
              <Badge badgeContent={notifications.filter(notification => !notification.readAt).length} color="error">
                <Notifications/>
              </Badge>
            </IconButton> */}

            {/* <UserMenu isOpen={isUserMenuOpen} onCloseRequest={handleCloseUserMenu}>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt={user?.displayName} src={user?.profilePicture}/>
              </IconButton>
            </UserMenu> */}
          </Authenticated>
        </Stack>
        <Stack direction="row" spacing={3} justifyContent="center" alignItems="center">
          {/* {user && (<Typography fontWeight={500} color="primary.light">{user?.trip?.location.title ? 'AWAY' : 'HOME'}</Typography>)} */}
          <Box
            component={Link}
            to={{
              pathname: router.home.path,
              search: networkSearchParams,
            }}
            display={{ xs: 'flex', sm: isLoggedIn ? 'none' : 'flex' }}>
            <Logo sx={{ width: 'auto', height: 24 }} />
          </Box>
        </Stack>
      </Toolbar>
      <Divider/>
    </AppBar>
  );
};
