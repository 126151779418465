import { camelizeObject, snakizeObject } from '@cvt/helpers/object';

import { mapUserData } from '@modules/Users/client/userClient.formatter';
import { getFormDataFromObject } from '@cvt/helpers/form';
import { getPictures } from '@shared/helpers/media';
import { mapEventData } from '@modules/Events/client/eventClient.formatter';

export const mapCommunityUserData = (data: Communities.CommunityUserApi): Communities.CommunityUser => {
  const user = camelizeObject<Communities.CommunityUser>(data);
  const profilePictures = getPictures(user.profilePicture);
  return {
    ...user,
    profilePicture: profilePictures?.[100],
    profilePictureSizes: profilePictures,
    displayName: `${user.firstName} ${user.lastName}`,
  };
};

export const mapExtendedCommunityUserData = (data: Communities.ExtendedCommunityUserApi): Communities.ExtendedCommunityUser => {
  const user = mapUserData(data);
  return {
    ...user,
    type: data.membership,
    isOwner: data.is_owner,
    status: data.community_status,
  };
};

export const mapCommunityData = (data: Communities.CommunityApi): Communities.Community => {
  const community = camelizeObject<Communities.Community>(data);
  const communityPictures = getPictures(community?.communityPicture);

  return {
    ...community,
    users: data.users.map(mapCommunityUserData),
    usersCount: data.users.length,
    channels: data.channels ? data.channels.map(camelizeObject<Communities.CommunityChannel>) : [],
    createdAt: new Date(data.created_at),
    updatedAt: new Date(data.updated_at),
    communityPicture: communityPictures?.[100],
    communityPictureSizes: communityPictures,
  };
};

export const mapChannelUserData = (data: Communities.Channels.ChannelUserApi): Communities.Channels.ChannelUser => {
  const { is_approved_member, ...userData } = data;
  const user = camelizeObject<Communities.Channels.ChannelUser>({ ...userData, status: is_approved_member ? 'active' : 'pending_acceptance' });
  const profilePictures = getPictures(user.profilePicture);

  return {
    ...user,
    profilePicture: profilePictures?.[100],
    profilePictureSizes: profilePictures,
    displayName: `${user.firstName} ${user.lastName}`,
  };
};

export const mapCommunityChannelData = (data: Communities.Channels.ChannelApi): Communities.Channels.Channel => ({
  ...camelizeObject<Communities.Channels.Channel>(data),
  event: data.event && mapEventData(data.event),
  users: data.users.map(mapChannelUserData),
  createdAt: new Date(data.created_at),
  updatedAt: new Date(data.updated_at),
});

export const mapCommunityCrudData = ({ communityPicture, ...data }: Partial<Communities.Crud>): FormData => {
  const apiData: Communities.CommunityApi = snakizeObject(data);

  const formData = getFormDataFromObject({
    ...apiData,
    community_picture: typeof communityPicture === 'string' ? undefined : communityPicture,
  });

  return formData;
};