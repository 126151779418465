import React from 'react';

import { MessageFeedbackView } from '@cvt/components/MessageFeedbackView';

import Image404 from '/404.png';

type Props = {
  message: string;
}
export const View404: React.FC<Props> = ({ message }) => {
  return (
    <MessageFeedbackView
      height="100%"
      imageSrc={Image404}
      message={message}
      slotProps={{ typography: { variant: 'h1', fontWeight: 500, color: 'secondary' } }}
    />
  );
};
