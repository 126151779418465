export const cacheKeys = {
  getContact: 'getContact',
  getContacts: 'getContacts',
  createContact: 'createContact',
  bulkCreateContact: 'bulkCreateContact',
  editContact: 'editContact',
  editContactConnection: 'editContactConnection',
  deleteContact: 'deleteContact',
  bulkInviteContact: 'bulkInviteContact',
};
