import 'react-international-phone/style.css';

import React from 'react';
import {
  CountryIso2,
  defaultCountries,
  FlagImage,
  parseCountry,
  usePhoneInput,
} from 'react-international-phone';
import {
  BaseTextFieldProps,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

export interface Props extends BaseTextFieldProps {
  value?: string;
  onChange: (phone: string) => void;
}

export const PhoneInput: React.FC<Props> = React.forwardRef(({
  value = '',
  onChange,
  ...restProps
}, ref) => {
  const { inputValue, handlePhoneValueChange, country, setCountry } =
    usePhoneInput({
      defaultCountry: 'us',
      value,
      countries: defaultCountries,
      onChange: (data) => {
        onChange(data.phone);
      },
    });

  const inputRef = React.useRef<HTMLInputElement | null>(null);

  // Ensure the caret is placed at the end of the input field
  const handleCaretToEnd = () => {
    if (inputRef.current) {
      const length = inputRef.current.value.length;
      inputRef.current.setSelectionRange(length, length);
    }
  };

  return (
    <TextField
      variant="outlined"
      label="Phone number"
      color="primary"
      placeholder="Phone number"
      value={inputValue}
      onChange={handlePhoneValueChange}
      // Uncomment to use phone number autofill
      // for now because of Safari don't use it
      // https://github.com/goveo/react-international-phone/issues/156
      // https://discussions.apple.com/thread/254572563?sortBy=best
      // type="tel"
      onClick={handleCaretToEnd} // Move caret to the end on click
      inputRef={(element) => {
        inputRef.current = element;
        if (typeof ref === 'function') {
          ref(element);
        } else if (ref) {
          ref.current = element;
        }
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment
            position="start"
            sx={{
              mr: '2px',
              ml: '-8px',
            }}
          >
            <Select
              MenuProps={{
                sx: {
                  height: 300,
                  width: 360,
                  top: 10,
                  left: -34,
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
              }}
              sx={{
                // Remove default outline
                fieldset: {
                  display: 'none',
                },
                // Update default spacing
                '.MuiSelect-select': {
                  p: 1,
                },
                svg: {
                  right: 0,
                },
              }}
              value={country.iso2}
              onChange={(e) => setCountry(e.target.value as CountryIso2)}
              renderValue={(value) => (
                <FlagImage iso2={value} style={{ display: 'flex' }} />
              )}
            >
              {defaultCountries.map((c) => {
                const { iso2, name, dialCode } = parseCountry(c);

                return (
                  <MenuItem key={iso2} value={iso2}>
                    <FlagImage
                      iso2={iso2}
                      style={{ marginRight: '8px' }}
                    />
                    <Typography mr={1}>{name}</Typography>
                    <Typography color="gray">+{dialCode}</Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </InputAdornment>
        ),
      }}
      {...restProps}
    />
  );
});