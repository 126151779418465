import React from 'react';
import noop from 'lodash/noop';
import { Contacts } from '@capacitor-community/contacts';

import { LocalizationContext } from '@cvt/contexts';

import { useContactCrud } from '../hooks/useContactCrud';
import { ContactForm } from './ContactForm';

export interface Props {
  contact: Contacts.Contact;
  onSuccess?: (contact: Contacts.Contact) => void;
}
export const EditContact: React.FC<Props> = ({ contact, onSuccess = noop }) => {

  const { dictionary } = React.useContext(LocalizationContext);

  const { editContact } = useContactCrud();

  const onSubmit = React.useCallback((data: Contacts.Crud) => {
    return editContact({
      id: contact.id,
      ...data,
    }).then(data => {
      onSuccess(data.data);
    });
  }, [contact, editContact, onSuccess]);

  return (
    <ContactForm
      defaultValues={{
        ...contact,
        location: contact.location ? {
          id: contact.location.id,
          name: contact.location.title,
          location: {
            lat: contact.location.latitude,
            lng: contact.location.longitude,
          },
        } : null,
      }}
      onSubmitRequest={onSubmit}
      onSubmitButtonText={dictionary.contacts.edit.buttonEdit}
      // Disable editing phone number fields and email for imported contacts
      disabledFields={contact?.externalId ? ['cel', 'tel', 'email'] : []}
    />
  );
};
