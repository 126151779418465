declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
  }
}

const Typography = () => {
  return {
    fontFamily: 'Roboto, sans-serif',
    fontSize: 15,
    h1: {
      fontSize: '2rem',
      fontWeight: 400,
      letterSpacing: 1.8,
    },
    h2: {
      fontSize: '1.375rem',
      fontWeight: 400,
      letterSpacing: 1.8,
    },
    h3: {
      fontSize: '1.125rem',
      fontWeight: 400,
    },
    h4: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    h5: {
      fontSize: '0.875rem',
      fontWeight: 500,
    },
    h6: {
      fontSize: '0.75rem',
      fontWeight: 500,
    },
    subtitle1: {
    },
    subtitle2: {
    },
    body1: {
      fontSize: 15,
    },
    body2: {
      fontSize: 13,
    },
    body3: {
      fontSize: 12,
    },
    button: {
    },
    caption: {
    },
    overline: {
    },
  };
};

export default Typography;
