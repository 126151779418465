import React from 'react';
import noop from 'lodash/noop';
import {
  SwipeableDrawer,
} from '@mui/material';

import { BodyLoading } from '@cvt/components/layout/BodyLoading';
import { isNative } from '@shared/helpers/environment';

import { useContact } from '../hooks/useContact';
import { ContactLargeCard } from '../components/ContactLargeCard';

type Props = {
  open: boolean;
  onClose: () => void;
  data?: {
    contactId: number;
  }
}

export const ViewContactDialog: React.FC<Props> = ({ open, onClose, data }) => {

  const { contact, status } = useContact({
    id: data?.contactId as number,
  }, {
    enabled: !!data?.contactId,
  });


  if (!data?.contactId) {
    return null;
  }

  return (
    <SwipeableDrawer
      disableSwipeToOpen
      keepMounted={false}
      anchor="bottom"
      open={open}
      onOpen={noop}
      onClose={onClose}
      PaperProps={{
        sx: theme => ({
          left: theme.spacing(1.5),
          right: theme.spacing(1.5),
          bottom: isNative() ? 'env(safe-area-inset-bottom)' : theme.spacing(1.5),
          borderRadius: theme.shape.borderRadius,
          maxWidth: theme.breakpoints.values.sm,
          margin: 'auto',
        }),
      }}
    >
      {(status === 'loading' || !contact) &&  <BodyLoading height={300}/>}
      {status === 'success' && contact && (
        <ContactLargeCard contact={contact} />
      )}
    </SwipeableDrawer>
  );
};
