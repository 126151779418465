import React from 'react';
import { useMount } from 'react-use';
import { useQueryClient } from '@tanstack/react-query';
import noop from 'lodash/noop';
import { Position } from '@capacitor/geolocation';
import { UnreadConversation } from 'talkjs/all';

import { PermissionContext } from '@cvt/contexts';

import { AuthContext } from '@modules/Auth/contexts';
import { useIpGeoLocation } from '@modules/Locations/hooks/useIpGeoLocation';
import { useCommunities } from '@modules/Communities/hooks/useCommunities';
import { cacheKeys } from '@modules/Communities/config';

import { useGeolocation } from '@shared/hooks/useGeolocation';
import { useTrackingPermission } from '@shared/hooks/useTrackingPermission';

import { CommunityUnreadConversation, ContextProps } from './types';

export const defaultContext: ContextProps = {
  user: undefined,
  location: {
    latitude: undefined,
    longitude: undefined,
  },
  updateLocation: noop,
  unreadMessages: {
    direct: [],
    communities: [],
  },
  setUnreadMessages: noop,
  isAdmin: false,
  isCustomer: false,
  isSubscriber: false,
  isPro: false,
};


export const UserContext = React.createContext(defaultContext);

export const UserProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { updatePermissions } = React.useContext(PermissionContext);
  const { user } = React.useContext(AuthContext);
  const { verifyPermission } = useTrackingPermission();
  const queryClient = useQueryClient();

  const { location: ipLocation } = useIpGeoLocation();
  const { getCurrentPosition } = useGeolocation();
  const [geoLocation, setGeoLocation] = React.useState<CVT.Maybe<Position>>(undefined);

  const location = React.useMemo(() => ({
    latitude: geoLocation?.coords.latitude || user?.trip?.location.latitude || user?.baseLocation?.latitude || ipLocation?.latitude,
    longitude: geoLocation?.coords.longitude || user?.trip?.location.longitude || user?.baseLocation?.longitude || ipLocation?.longitude,
  }), [user, geoLocation, ipLocation]);

  const updateLocation = React.useCallback(async () => {
    const position = await getCurrentPosition();
    setGeoLocation(position);
  }, [getCurrentPosition]);

  const isPro = React.useMemo(() => user?.userType === 'pro', [user]);
  const isSubscriber = React.useMemo(() => user?.userType !== 'pro', [user]);

  const [unreadMessages, setUnreadMessages] = React.useState<UnreadConversation[]>([]);

  // Used to filter out unread messages that are part of communities
  const { communities, status: communitiesStatus } = useCommunities({
    limit: 5000,
  }, {
    enabled: !!user?.id,
  });

  const communitiesUserIsMember = React.useMemo(() => {
    return communities.filter(community => community.users.some(member => member.id === user?.id));
  }, [communities, user]);

  const _unreadMessages = React.useMemo(() => {
    if (communitiesStatus !== 'success') {
      return {
        direct: [],
        communities: [],
      };
    }
    return unreadMessages.reduce((prev, curr) => {
      // Check main channel and sub channels that match unread conversation id
      const currCommunityWithUnreadMessages = communitiesUserIsMember.find(community => community.mainChannel === curr.conversation.id || community.channels.some(channel => channel.id === curr.conversation.id));

      if (currCommunityWithUnreadMessages) {
        const currCommunityWithMutedNotJoinedChannels = currCommunityWithUnreadMessages?.channels.some(channel => curr.conversation.id === channel.id && (channel.muted || !channel.isMember));

        if (currCommunityWithMutedNotJoinedChannels) {
          return {
            ...prev,
          };
        }

        // Invalidate community cache when user received new message
        if (currCommunityWithUnreadMessages.handle) {
          queryClient.invalidateQueries([cacheKeys.getCommunity, currCommunityWithUnreadMessages.handle]);
        }
        queryClient.invalidateQueries([cacheKeys.getCommunity, currCommunityWithUnreadMessages.id.toString()]);

        return {
          ...prev,
          communities: [...prev.communities, { ...curr, communityId: currCommunityWithUnreadMessages.id }],
        };
      }

      return {
        ...prev,
        direct: [...prev.direct, curr],
      };
    }, {
      direct: [],
      communities: [],
    } as {
      direct: UnreadConversation[],
      communities: CommunityUnreadConversation[],
    });
  }, [communitiesStatus, communitiesUserIsMember, queryClient, unreadMessages]);

  React.useEffect(() => {
    updatePermissions(['users.pro'], isPro);
  }, [updatePermissions, isPro]);

  useMount(() => {
    verifyPermission();
  });

  return (
    <UserContext.Provider
      value={{
        user,
        unreadMessages: _unreadMessages,
        setUnreadMessages,
        location,
        updateLocation,
        isAdmin: false,
        isCustomer: true,
        isSubscriber,
        isPro,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const UserConsumer = UserContext.Consumer;
