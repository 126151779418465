import React, { PropsWithChildren } from 'react';
import { addDays } from 'date-fns';
import { Box, Container, LinearProgress, Stack, Typography } from '@mui/material';
import remove from 'lodash/remove';
import concat from 'lodash/concat';

import { useRouter } from '@cvt/hooks/useRouter';
import { DialogContext } from '@cvt/contexts';
import { useQueryState } from '@cvt/hooks/useQueryState';

import { SwipableCardStack } from '@shared/components/SwipableCardStack';
import { SwipableCard } from '@shared/components/SwipableCard';

import { useLocation } from '@modules/Locations/hooks/useLocation';
import { formatTripDates } from '@modules/Trips/helpers/date';
import { useContacts } from '@modules/Contacts/hooks/useContacts';
import { useContactMessage } from '@modules/Contacts/hooks/useContactMessage';

import { useConnectionChange } from '@modules/Connections/hooks/useConnectionChange';
import { ContactLargeCard } from '@modules/Contacts/components/ContactLargeCard';

import { UserContext } from '../contexts';
import { useUsers } from '../hooks/useUsers';
import { UserLargeCard, UserLargeCardSkeleton } from './UserLargeCard';


interface Props {
}

export const SwipableUsers: React.FC<PropsWithChildren<Props>> = () => {
  const { user: me } = React.useContext(UserContext);
  const { openDialog } = React.useContext(DialogContext);

  const router = useRouter();

  const [dateLte] = useQueryState('dateLte');
  const [dateGte] = useQueryState('dateGte');
  const [locationId] = useQueryState('locationId');
  const [userId] = useQueryState('userId');

  const [count, setCount] = React.useState(0);

  const _dateLte = dateLte ? new Date(dateLte) : new Date();
  const _dateGte = dateGte ? new Date(dateGte) : addDays(new Date(), 7);
  const _locationId = locationId || me?.trip?.location.id || me?.baseLocation?.id;

  const { location } = useLocation({
    // @ts-ignore
    id: _locationId,
  }, {
    enabled: !!_locationId,
  });

  const { onConnectionTypeChange } = useConnectionChange({});
  const { status, users } = useUsers({
    dateGte: dateGte ? new Date(dateGte) : undefined,
    dateLte: dateLte ? new Date(dateLte) : undefined,
    location: locationId || me?.trip?.location.id || me?.baseLocation?.id,
    limit: 100,
  });

  const { contacts, status: contactsStatus } = useContacts({
    user: me?.id,
    limit: 10000,
    ordering: 'first_name',
    location: _locationId,
  });
  const { messageContactBySms } = useContactMessage();


  const filteredUsers: Users.User[] = React.useMemo(() => {
    if (!userId) {
      return users;
    }
    const currUsers = [...users];

    // Remove the user with the matching userId
    const matchedUser = remove(currUsers, user => user.id.toString() === userId);

    // Concatenate the matched user at the beginning
    return concat(matchedUser, currUsers);
  }, [users, userId]);
  
  return (
    <Stack height="100%">
      <Container maxWidth="sm" sx={{ height: '100%', overflow: 'hidden' }}>
        <Box pt={{ xs: 9, sm: 11 }} pb={2} height="100%">
          <Stack height="100%">
            <Stack flexDirection="column" justifyContent="center" alignItems="center" mb={2}>
              {/* <Typography variant="body2" fontWeight={500}>{count} left</Typography> */}
              <Typography variant="h1" fontWeight={500}>People Around</Typography>
              <Typography variant="body1">{location?.title} - {formatTripDates(_dateGte, _dateLte)}</Typography>
            </Stack>
            {status !== 'success' || contactsStatus !== 'success' ? (
              <UserLargeCardSkeleton/>
            ) : (
              <SwipableCardStack setCount={setCount} onFinish={() => router.dashboard.users.recent.go()}>
                {filteredUsers.map(user => (
                  <SwipableCard
                    key={user.id}
                    onClick={() => openDialog('viewUser', { userId: user.id })}
                    onSwipeRight={() => {
                      onConnectionTypeChange('close', user);
                    }}
                  >
                    <UserLargeCard user={user}/>
                  </SwipableCard>
                ))}
                {contacts.map(contact => (
                  <SwipableCard
                    key={contact.id}
                    onClick={() => openDialog('viewContact', { contactId: contact.id })}
                    onSwipeRight={() => {
                      messageContactBySms(contact);
                    }}
                  >
                    <ContactLargeCard contact={contact}/>
                  </SwipableCard>
                ))}
              </SwipableCardStack>
            )}
          </Stack>
        </Box>
      </Container>
      <LinearProgress value={count / filteredUsers.length * 100} variant="determinate" sx={{ height: '1px' }} />
    </Stack>
  );
};
