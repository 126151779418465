import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Contacts, GetContactsOptions, ContactPayload } from '@capacitor-community/contacts';
import { NativeSettings, IOSSettings, AndroidSettings } from 'capacitor-native-settings';
import { Link, Typography } from '@mui/material';
import * as Sentry from '@sentry/react';

import { DialogContext, PermissionContext } from '@cvt/contexts';
import { useDictionary } from '@cvt/hooks/useDictionary';
import { useRouter } from '@cvt/hooks/useRouter';
import { isNative } from '@shared/helpers/environment';
import { UserContext } from '@modules/Users/contexts';

import { contactsClient } from '../client/contactClient';
import { mapContactPickerContact, mapContact } from '../helpers/mobile';
import { useContactCrud } from './useContactCrud';

export const useMobileContactsImport = () => {
  const { permissions, updatePermissions } = React.useContext(PermissionContext);
  const { asyncConfirmation } = React.useContext(DialogContext);
  const { user } = React.useContext(UserContext);

  const dictionary = useDictionary();
  const router = useRouter();
  const { bulkCreateContact, editContact } = useContactCrud();


  const checkPermission = React.useCallback(() => {
    if (isNative()) {
      Contacts.checkPermissions().then(value => {
        updatePermissions(['contacts.import'], value.contacts === 'granted');
      });
    }
  }, [updatePermissions]);

  const verifyPermission = React.useCallback(async (prompt: boolean = true) => {
    if (isNative()) {
      if (permissions.contacts.import) {
        return Promise.resolve(true);
      }

      if (prompt) {
        const userConfirmed = await asyncConfirmation({
          title: dictionary.contacts.import.importContactsPermissionConfirmation,
          content: (
            <Typography variant="body2">
              {dictionary.contacts.import.importContactsPermissionDescription(
                <Link to={import.meta.env.VITE__CVT_REACT_APP_BASE_URL + router.privacyPolicy.path} target="_blank" component={RouterLink}>{dictionary.auth.signUp.privacyPolicy}</Link>,
              )}
            </Typography>
          ),
          cancelLabel: dictionary.contacts.import.importContactsPermissionButtonSkip,
          confirmLabel: dictionary.contacts.import.importContactsPermissionButtonConfirm,
        });
  
        if (userConfirmed) {
          const permission = await Contacts.requestPermissions();

          if (permission.contacts === 'denied') {
            NativeSettings.open({
              optionAndroid: AndroidSettings.ApplicationDetails, 
              optionIOS: IOSSettings.App,
            });
            return Promise.reject();
          } else if (permission.contacts === 'granted') {
            updatePermissions(['contacts.import'], true);
            return Promise.resolve(true);
          }
        }
      }
  
      return Promise.reject();
    }
    return Promise.reject();
  }, [dictionary, asyncConfirmation, router, permissions.contacts.import, updatePermissions]);

  // const onImport = React.useCallback((contacts: Contacts.Crud[]) => {
  //   return bulkCreateContact(contacts);
  // }, [bulkCreateContact]);

  const onImport = React.useCallback(async (contacts: Contacts.Crud[]) => {
    const chunkSize = 1000;
    const promises = contacts.reduce((acc, _, i) => {
      if (i % chunkSize === 0) {
        const chunk = contacts.slice(i, i + chunkSize);
        acc.push(bulkCreateContact(chunk));
      }
      return acc;
    }, [] as Promise<any>[]);

    const results = await Promise.allSettled(promises);
    console.log('All contacts imported successfully', results);
  }, [bulkCreateContact]);

  const onUpdate = React.useCallback(async (contacts: Contacts.Edit[]) => {
    const promises = contacts.map(contact => editContact(contact));

    const results = await Promise.allSettled(promises);
    console.log('All contacts updated successfully', results);
  }, [editContact]);

  const importContactsFromNative = React.useCallback(async (prompt: boolean) => {
    try {
      if (isNative()) {
        const hasPermission = await verifyPermission(prompt);

        if (hasPermission && user?.id) {
          const contacts = await contactsClient.getContacts({
            user: user.id,
            limit: 100000,
          });

          const existingContacts = contacts.data.results.reduce<{[key: string]: Contacts.Contact}>((acc, contact) => {
            if (contact.externalId) {
              return {
                ...acc,
                [contact.externalId]: contact,
              };
            }
            return acc;
          }, {});
          const existingIds = Object.keys(existingContacts);

          const projection: GetContactsOptions['projection'] = {
            // Specify which fields should be retrieved.
            name: true,
            organization: true,
            image: false,
            phones: true,
            postalAddresses: true,
            emails: true,
            note: true,
          };

          const result = await Contacts.getContacts({
            projection,
          });

          if (result?.contacts) {
            const filteredContacts = {
              newContacts: [] as ContactPayload[],
              updatedContacts: [] as Omit<Contacts.Edit, 'owner' | 'sorted' | 'connectionType'>[],
            };

            for (const contact of result.contacts) {
              // Filter out existing contacts in our list
              if (!existingIds.includes(contact.contactId as string)) {
                filteredContacts.newContacts.push(contact);
              } else {
                const phoneContact = await mapContact(contact);

                // Filter out updated contacts in our imported contact list
                if (phoneContact.externalId) {
                  const { cel, tel, firstName, lastName, email, note, id } = existingContacts[phoneContact.externalId];

                  // Check if contact has been updated (with phone numbers, firstName, lastName, location, and email)
                  const isContactUpdated =
                    (phoneContact.cel || '').replaceAll(' ', '') !== (cel || '') ||
                    (phoneContact.tel || '').replaceAll(' ', '') !== (tel || '') ||
                    (phoneContact.firstName || '').trim() !== (firstName || '') ||
                    (phoneContact.lastName || '').trim() !== (lastName || '') ||
                    (phoneContact.email || '') !== (email || '') ||
                    (phoneContact.note || '') !== (note || '');
                    // (phoneContact.address || '') !== (address || '');
                  if (isContactUpdated) {
                    filteredContacts.updatedContacts.push({ ...phoneContact, id: id });
                  }
                }
              }
            }

            // Limit import to 5000 at a time
            const newContacts = filteredContacts.newContacts.slice(0, 5000);
            const updatedContacts = filteredContacts.updatedContacts.slice(0, 5000);

            const mappedContactsList: Contacts.Crud[] = await Promise.all(newContacts
              .map(async contact => {
                const mappedContact = await mapContact(contact);
                return {
                  ...mappedContact,
                  owner: user?.id,
                };
              }, []));

            const importNewContactsList = mappedContactsList
              // Filter out contacts without numbers or email
              .filter(contact => contact.cel || contact.tel || contact.email);

            const importUpdatedContactsList: Contacts.Edit[] = updatedContacts
              // Filter out contacts without numbers or email
              .filter(contact => contact.cel || contact.tel || contact.email)
              .map(contact => ({ ...contact, owner: user?.id }));

            if (importNewContactsList.length > 0 || importUpdatedContactsList.length > 0) {
              try {
                if (importNewContactsList.length > 0) {
                  await onImport(importNewContactsList);
                }

                if (importUpdatedContactsList.length > 0) {
                  await onUpdate(importUpdatedContactsList);
                }
              } catch(err) {
                Sentry.captureException(err);
              }
              return true;
            }
          }

          return false;
        }
      }
    } catch(err) {
      console.error('No permission to access Contacts API');
    }
  }, [verifyPermission, onImport, onUpdate, user]);

  const importContactsFromContactPicker = React.useCallback(async () => {
    if (user?.id) {
      // Available properties include 'name', 'tel', 'email', 'address', and 'icon'
      const propertiesAvailable = ['name', 'tel', 'email', 'address', 'icon'];

      // Open the contact picker with properties
      // @ts-ignore
      const pickedContacts: Contacts.ContactPickerContact[] = await navigator?.contacts.select(propertiesAvailable, { multiple: true });

      const contacts = await contactsClient.getContacts({
        user: user.id,
        limit: 100000,
      });
      const emailSet = new Set<string>();
      const telSet = new Set<string>();

      contacts.data.results.forEach(contact => {
        if (contact.email) emailSet.add(contact.email);
        if (contact.tel) telSet.add(contact.tel);
        if (contact.cel) telSet.add(contact.cel);
      });

      if (pickedContacts.length > 0) {
        const filteredContacts = pickedContacts
          // Filter out existing contacts in our list
          .filter(contact => {
            const emailMatch = contact.email.some(email => emailSet.has(email));
            const telMatch = contact.tel.some(tel => telSet.has(tel));

            return !emailMatch || !telMatch;
          })
          // Limit import to 1000 at a time
          .slice(0, 5000);

        const list: Contacts.Crud[] = filteredContacts.map(contact => {
          const mappedContact = mapContactPickerContact(contact);
          return {
            ...mappedContact,
            owner: user?.id,
          };
        }, []);

        const importList = list
          // Filter out contacts without numbers or email
          .filter(contact => contact.cel || contact.tel || contact.email);

        if (importList.length > 0) {
          try {
            await onImport(importList);
          } catch(err) {
            Sentry.captureException(err);
          }
          return true;
        }
      }
    }

    return false;
    
  }, [onImport, user]);

  const importContacts = React.useCallback((prompt: boolean) => {
    const isContactPickerSupported = 'contacts' in navigator && 'ContactsManager' in window;

    if (isNative()) {
      return importContactsFromNative(prompt);
    } else if(isContactPickerSupported) {
      return importContactsFromContactPicker();
    }

    return Promise.reject('Not supported');
  }, [importContactsFromNative, importContactsFromContactPicker]);

  React.useEffect(() => {
    checkPermission();
  }, [checkPermission]);

  return {
    verifyPermission,
    importContacts,
  };
};
