import { AxiosResponse } from 'axios';
import { endOfDay } from 'date-fns';

import { request } from '@cvt/clients/baseClient';
import { camelizeObject, snakizeObject } from '@cvt/helpers/object';

import { isAndroid, isIos } from '@shared/helpers/environment';

import { mapTripData } from '@modules/Trips/client/tripClient.formatter';

import { mapUserCrudData, mapUserData } from './userClient.formatter';

const usersApiBaseUrl = import.meta.env.VITE__CVT_API_URL;

const getUser = (params: { id: number | string }, authenticate: boolean = true): Promise<AxiosResponse<Users.User>> => {
  return request({
    options: {
      url: `${usersApiBaseUrl}/users/${params.id}/`,
      method: 'GET',
    },
    authenticate,
  }).then((data: AxiosResponse<Users.UserApi>) => ({
    ...data,
    data: mapUserData(data.data),
  }));
};

const getUsers = (params: Users.GetListParams): Promise<AxiosResponse<CVT.Query.PaginatedResults<Users.User>>> => {
  const now = new Date();
  const eod = endOfDay(now);
  return request({
    options: {
      url: `${usersApiBaseUrl}/users/`,
      method: 'GET',
      params: {
        ...snakizeObject(params),
        date_gte: params.dateGte ? params.dateGte.toISOString() : now.toISOString(),
        date_lte: params.dateLte ? params.dateLte.toISOString() : eod.toISOString(),
      },
    },
  }).then((data: AxiosResponse<CVT.Query.PaginatedResults<Users.UserApi>>) => ({
    ...data,
    data: {
      ...data.data,
      results: data.data.results
        .map(mapUserData)
        .filter(user => !!user.baseLocation?.id)
        .sort((a, b) => a.firstName.localeCompare(b.firstName)),
    },
  }));
};

const searchUsers = (params: Users.GetSearchListParams): Promise<AxiosResponse<CVT.Query.PaginatedResults<Users.User>>> => {
  return request({
    options: {
      url: `${usersApiBaseUrl}/search/users/`,
      method: 'GET',
      params: {
        ...snakizeObject(params),
      },
    },
  }).then((data: AxiosResponse<CVT.Query.PaginatedResults<Users.UserApi>>) => ({
    ...data,
    data: {
      ...data.data,
      results: data.data.results.map(mapUserData),
    },
  }));
};

const createUser = (data: Users.Create): Promise<AxiosResponse<Users.User>> => {
  return request({
    options: {
      url: `${usersApiBaseUrl}/users/`,
      method: 'POST',
      data: mapUserCrudData(data),
    },
  }).then((data: AxiosResponse<Users.UserApi>) => ({
    ...data,
    data: mapUserData(data.data),
  }));
};

const editUser = (data: Users.Edit): Promise<AxiosResponse<Users.User>> => {
  return request({
    options: {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      url: `${usersApiBaseUrl}/users/${data.id}/`,
      method: 'PUT',
      data: mapUserCrudData(data),
    },
  }).then((data: AxiosResponse<Users.UserApi>) => ({
    ...data,
    data: mapUserData(data.data),
  }));
};

const deleteUser = (params: { id: number | string }): Promise<AxiosResponse> => {
  return request({
    options: {
      url: `${usersApiBaseUrl}/users/${params.id}/`,
      method: 'DELETE',
    },
  });
};

const inviteUser = (data: { email: string }): Promise<AxiosResponse> => {
  return request({
    options: { 
      url: `${usersApiBaseUrl}/user-invitations/`,
      method: 'POST',
      data,
    },
  }).then((data: AxiosResponse) => ({
    ...data,
    data: camelizeObject(data.data),
  }));
};

const setDeviceInfo = async (token: string): Promise<unknown> => {
  let deviceType = 'web';
  if (isIos()) {
    deviceType = 'ios';
  }
  if (isAndroid()) {
    deviceType = 'android';
  }

  return request({
    options: {
      url: `${usersApiBaseUrl}/notifications/add-push-device/`,
      method: 'POST',
      data: {
        device_token: token,
        device_type: deviceType,
      },
    },
  });
};

const notifyUsers = (data: Users.NotifyUsers): Promise<AxiosResponse<Trips.Trip>> => {
  return request({
    options: {
      url: `${usersApiBaseUrl}/trips/${data.id}/`,
      method: 'PATCH',
      data: snakizeObject(data),
    },
  }).then((data: AxiosResponse<Trips.TripApi>) => ({
    ...data,
    data: mapTripData(data.data),
  }));
};

export const userClient = {
  getUser,
  getUsers,
  searchUsers,
  createUser,
  editUser,
  deleteUser,
  inviteUser,
  setDeviceInfo,
  notifyUsers,
};
