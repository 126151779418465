import { format } from 'date-fns';

import { getFormDataFromObject } from '@cvt/helpers/form';
import { camelizeObject, snakizeObject } from '@cvt/helpers/object';

export const mapContactData = (data: Contacts.ContactApi): Contacts.Contact => ({
  ...camelizeObject<Contacts.Contact>(data),
  cel: data.cel?.replaceAll(' ', '') || null,
  tel: data.tel?.replaceAll(' ', '') || null,
  displayName: `${data.first_name} ${data.last_name || ''}`,
  invitedAt: data.invited_at ? new Date(data.invited_at) : null,
});

export const mapContactCrudData = ({ image, ...data }: Partial<Contacts.Crud>): FormData => getFormDataFromObject({
  ...snakizeObject(data),
  invited_at: data.invitedAt ? format(data.invitedAt, 'yyyy-MM-dd') + 'T00:00:00.00Z' : null,
  image: typeof image === 'string' ? undefined : image,
});
