import React from 'react';
import { motion, AnimatePresence, PanInfo, useDragControls } from 'framer-motion';
import { Box, BoxProps, useTheme } from '@mui/material';

export const StackedCards: React.FC<React.PropsWithChildren<BoxProps>> = ({ children, ...props }) => {
  const theme = useTheme();
  const [stack, setStack] = React.useState(React.Children.toArray(children));
  const [isDragging, setIsDragging] = React.useState(false);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const dragStartY = React.useRef(0);

  const _stack = React.useMemo(() => {
    return stack.slice(0, 30);
  }, [stack]);

  const controls = useDragControls();

  const rotateCards = React.useCallback((moveUp: boolean, count: number) => {
    setStack(prevCards => {
      const newCards = [...prevCards];
      if (moveUp) {
        for (let i = 0; i < count; i++) {
          newCards.push(newCards.shift()!);
        }
      } else {
        for (let i = 0; i < count; i++) {
          newCards.unshift(newCards.pop()!);
        }
      }
      return newCards;
    });
  }, []);

  const handleWheel = React.useCallback((event: React.WheelEvent) => {
    if (isDragging) {
      const scrollAmount = event.deltaY;
      rotateCards(scrollAmount > 0, 1);
    }
  }, [rotateCards, isDragging]);
  
  const handleTouchStart = React.useCallback((event: TouchEvent) => {
    dragStartY.current = event.touches[0].clientY;
  }, []);

  const handleTouchMove = React.useCallback((event: TouchEvent) => {
    if (isDragging) {
      const currentY = event.touches[0].clientY;
      const dragDistance = currentY - dragStartY.current;
      const flipThreshold = 20;
      if (Math.abs(dragDistance) > flipThreshold) {
        rotateCards(dragDistance < 0, 1);
        dragStartY.current = currentY;
      }
    }
  }, [rotateCards, isDragging]);

  const onDragStart = React.useCallback((event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
    setIsDragging(true);
    dragStartY.current = info.point.y;
  }, []);

  const onDrag = React.useCallback((event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
    const dragDistance = info.point.y - dragStartY.current;
    const flipThreshold = 20;
    if (Math.abs(dragDistance) > flipThreshold) {
      rotateCards(dragDistance < 0, 1);
      dragStartY.current = info.point.y;
    }
  }, [rotateCards]);

  const onDragEnd = React.useCallback(() => {
    setIsDragging(false);
  }, []);

  const paddingBottom = React.useMemo(() => {
    const cardCount = stack.length;
    if (cardCount === 0) return 0;
    if (cardCount === 1) return 2;
    if (cardCount === 2) return 7;
    if (cardCount === 3) return 11.5;
    return 13.5; // For 3 or more cards
  }, [stack.length]);

  // Watching over changes in children and update the stack
  React.useEffect(() => {
    setStack(React.Children.toArray(children));
  }, [children]);

  React.useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const wrappedHandleWheel = (e: WheelEvent) => {
        e.preventDefault();
        handleWheel(e as any);
      };
      const wrappedHandleTouchMove = (e: TouchEvent) => {
        e.preventDefault();
        handleTouchMove(e);
      };
      container.addEventListener('wheel', wrappedHandleWheel, { passive: false });
      container.addEventListener('touchstart', handleTouchStart, { passive: true });
      container.addEventListener('touchmove', wrappedHandleTouchMove, { passive: false });
    }
    return () => {
      if (container) {
        container.removeEventListener('wheel', handleWheel as any);
        container.removeEventListener('touchstart', handleTouchStart);
        container.removeEventListener('touchmove', handleTouchMove);
      }
    };
  }, [handleWheel, handleTouchStart, handleTouchMove]);

  const maxInitialDistance = 3; // Maximum initial distance in rem

  return (
    <Box position="relative" paddingBottom={paddingBottom}>
      <Box
        sx={{ aspectRatio: '4 / 2' }}
      >
        <AnimatePresence initial={false}>
          {_stack.map((card, index) => (
            <motion.div
              key={React.isValidElement(card) ? card.key : index}
              style={{
                width: '100%',
                position: 'absolute',
                zIndex: _stack.length - index,
                top: 0,
                perspective: '1000px',
              }}
              initial={{ 
                y: `${Math.min((_stack.length - 1), maxInitialDistance) * 2}rem`, 
                scale: 0.94, 
                rotateX: 0,
                opacity: 0, 
              }}
              animate={{
                y: index < 3 ? `${index * 3}rem` : `${2 * 2 + 3}rem`,
                scale: index < 3 ? 1 - (index * 0.02) : 0.94,
                rotateX: isDragging ? 15 : 0,
                opacity: 1,
                transition: {
                  y: { type: 'spring', stiffness: 300, damping: 30 },
                  scale: { type: 'spring', stiffness: 300, damping: 30 },
                  opacity: { duration: 0.2 },
                },
              }}
              exit={{
                y: `${Math.min(_stack.length, maxInitialDistance) * 2}rem`,
                scale: 0.94,
                opacity: 0,
                transition: { duration: 0.2 },
              }}
            >
              {React.cloneElement(card as React.ReactElement<any>, { hideShadow: index > 3, isFront: index === 0 })}
            </motion.div>
          ))}
        </AnimatePresence>
        {/* Draggable area for lower cards */}
        {/* <motion.div
          ref={containerRef}
          style={{
            position: 'absolute',
            height: theme.spacing(paddingBottom),
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: _stack.length - 1,
          }}
          drag="y"
          dragControls={controls}
          dragConstraints={{ top: 0, bottom: 0 }}
          dragElastic={0.05}
          onDragStart={onDragStart}
          onDrag={onDrag}
          onDragEnd={onDragEnd}
        /> */}
      </Box>
    </Box>
  );
};